import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout headline="Hey, I´m Chris">
    <SEO siteTitle="Home" keywords={[`mychriz`,`web design`,`web development`, `design pattern`,`css`,`react`,`gatsbyjs`]} />
    <p className="p">
      Self-description, in one sentence - "I'm a web design/development lover, 
      trapped in an engineer's body who designs and codes for fun and wishes to be a writer, too." 
      You can find a more detailed version about me <Link to="/about/">here</Link>.
    </p>   
    <p className="p">
      You can get an impression of my work on my <Link to="/work/">portfolio</Link> page. 
      This is still in its infancy, 
      but each portfolio starts with a project. Unfortunately, a day has only 24 hours.
    </p>
    <p className="p">
      I am always looking for ideas, suggestions for improvement and new opportunities 
      to improve my skills. If you want to show me something or have something to tell me 
      then just <Link to="/contact/">write</Link> to me. Either use the contact form or look 
      at my <a href="https://twitter.com/mychriz2?lang=de" rel="noopener noreferrer" target="_blank">Twitter</a> account 
      or <a href="https://medium.com/@mychriz" rel="noopener noreferrer" target="_blank">Medium</a>. 
    </p>
    <p className="p">
      Cheerio, <br />
      Chris
    </p>
  </Layout>
)

export default IndexPage
